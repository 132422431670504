html,
body,
#root {
  width: 100%;
}
body {
  font-family: 'Exo 2', sans-serif;
  background: #011b2d url('bg.jpg') repeat fixed 0 0/2560px 200px;
}

.fail {
  color: #d9534f;
}
.success {
  color: #5cb85c;
}
.neutral {
  color: #f0ad4e;
}

.failure,
.LEO,
.PO,
.ISS,
.SSO,
.MEO {
  align-items: flex-end;
}
.GTO,
.GEO {
  align-items: center;
}
.ES-L1 {
  align-items: flex-start;
}
